<template>
  <v-main class="bg">
    Not found
  </v-main>
</template>

<script>

export default {
}
</script>